import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid, GridCol } from '@naf/grid';
import { ButtonLink } from '@naf/button-link';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Message } from '@naf/input';
import { DocumentSnapshotsBlockType } from '../../../../../types/DocumentSnapshotsBlockType';
import { NewsCard } from './News/NewsCard';
import { useFetch } from '../../../hooks/useFetch';
import { ArticleReference } from '../../../../../types/articleReferenceType';
import { NewsCardLoader } from '../../skeleton-loaders/NewsCardLoader';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';

export const DocumentSnapshots = ({
  block: {
    data: { internalEndpoint, internalLink, title },
  },
}: {
  block: DocumentSnapshotsBlockType;
}) => {
  const { data, isLoading, error } = useFetch<{ items: ArticleReference[] }>({ url: internalEndpoint });
  const link = useDocumentUrlWithNode(internalLink);
  if (error) return <Message error>Klarte ikke å laste sist publiserte artikler</Message>;
  return (
    <div>
      <HeaderWrapper>
        <Text variant={TextVariant.Header2}>{title}</Text>
        {link && <ButtonLink as={Link} to={link} text="Se alle" />}
      </HeaderWrapper>
      <Grid>
        {data?.items.map((article) => (
          <NewsCard article={article} key={article.id} />
        ))}
        {!data && isLoading && (
          <>
            <GridCol s="12" m="6" l="6" xl="4">
              <NewsCardLoader />
            </GridCol>
            <GridCol s="12" m="6" l="6" xl="4">
              <NewsCardLoader />
            </GridCol>
            <GridCol s="12" m="6" l="6" xl="4">
              <NewsCardLoader />
            </GridCol>
          </>
        )}
      </Grid>
    </div>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
